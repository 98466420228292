<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="1400px"
    v-if="isDataLoadedFromServer"
    style="background-color: white !important; height: 80vh !important"
  >
    <div class="bg-white poppins modal-root">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">
          {{ $t("create_return") }}
        </h3>
        <div class="btn btn-sm btn-icon btn-active-light" @click="toggleModal">
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-flex flex-column mx-5 modal-body">
        <!--begin::Body-->

        <v-stepper
          v-model="tab"
          non-linear
          outlined
          rounded="4"
          flat
          v-if="isStepperReady"
          @change="updateValidationStateOfSteps"
        >
          <v-stepper-header class="step-head-main">
            <v-stepper-step
              v-for="(item, i) in categories"
              :key="i"
              class="step-head"
              complete
              editable
              complete-icon="mdi-check-circle"
              edit-icon="mdi-check"
              error-icon="mdi-alert-circle"
              :step="i"
              :rules="[
                () => {
                  return item.valid;
                },
              ]"
            >
              {{ item.name }}
              <small v-if="!item.valid">{{ $t("invalid") }}</small>
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items class="">
            <v-stepper-content step="0" key="Order Details" class="pt-2">
              <div class="row">
                <!-- row 1 -->
                <div
                  v-if="!isClient"
                  class="col-12"
                  :class="{
                    'col-sm-6': !isClient,
                    // 'col-sm-6': order_details.collection_type_id === 2,
                    // 'col-sm-4': order_details.collection_type_id === 1,
                  }"
                >
                  <v-autocomplete
                    v-model="$v.order_details.customer_id.$model"
                    :label="$t('customer')"
                    item-text="text"
                    item-value="index"
                    :items="serverData.customers"
                    clearable
                    outlined
                    dense
                    :error-messages="customerIdErrors"
                    @input="$v.order_details.customer_id.$touch()"
                    @blur="$v.order_details.customer_id.$touch()"
                  ></v-autocomplete>
                </div>

                <div
                  class="col-12"
                  :class="{
                    'col-sm-6': !isClient,
                    'col-sm-4': isClient,
                    // 'col-sm-6':
                    //   isClient || order_details.collection_type_id === 2,
                    // 'col-sm-4':
                    //   !isClient || order_details.collection_type_id === 1,
                    // 'col-sm-12':
                    //   isClient && order_details.collection_type_id === 2,
                  }"
                >
                  <v-autocomplete
                    v-model="$v.order_details.collection_type_id.$model"
                    :label="$t('collection_type')"
                    item-text="text"
                    item-value="index"
                    :items="collectionTypes"
                    clearable
                    outlined
                    dense
                    :error-messages="collectionTypeIdErrors"
                    @input="$v.order_details.collection_type_id.$touch()"
                    @blur="$v.order_details.collection_type_id.$touch()"
                  ></v-autocomplete>
                </div>

                <!--                <div-->
                <!--                  class="col-12"-->
                <!--                  v-if="order_details.collection_type_id !== 2"-->
                <!--                  :class="{ 'col-sm-6': isClient, 'col-sm-4': !isClient }"-->
                <!--                >-->
                <!--                  <v-datetime-picker-->
                <!--                    ref="datetime"-->
                <!--                    label="Expected collection time"-->
                <!--                    dateFormat="Y-m-d"-->
                <!--                    timeFormat="H:i:s"-->
                <!--                    :textFieldProps="{-->
                <!--                      outlined: true,-->
                <!--                      dense: true,-->
                <!--                      'prepend-icon': 'mdi-calendar',-->
                <!--                    }"-->
                <!--                    v-model="order_details.expected_collection_date"-->
                <!--                  >-->
                <!--                  </v-datetime-picker>-->
                <!--                </div>-->
                <!-- row 1 end -->

                <div
                  class="col-12"
                  :class="{
                    'col-sm-6': !isClient,
                    'col-sm-4': isClient,
                  }"
                >
                  <!--                  :class="{-->
                  <!--                  'col-sm-4':-->
                  <!--                  isClient || order_details.collection_type_id === 2,-->
                  <!--                  'col-sm-6':-->
                  <!--                  !isClient || order_details.collection_type_id === 1,-->
                  <!--                  }"-->
                  <v-text-field
                    v-model="order_details.original_client_reference"
                    :label="$t('order_reference_number')"
                    hint="Merchant order reference number"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>

                <div
                  class="col-12"
                  :class="{
                    'col-sm-6': !isClient,
                    'col-sm-4': isClient,
                  }"
                >
                  <v-text-field
                    v-model="order_details.original_awb"
                    :label="$t('order_delivery_number')"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>

                <div class="col-12">
                  <v-text-field
                    v-model="order_details.note"
                    :label="$t('reason')"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="1" class="py-0">
              <div class="mb-1">
                <v-card class="px-4">
                  <v-card-title class="h2 px-0 py-1">
                    {{ $t("sender_details") }}
                  </v-card-title>

                  <div class="row">
                    <!-- row 1 -->
                    <div
                      class="col-12"
                      :class="[
                        order_details.collection_type_id === 1
                          ? 'col-sm-3'
                          : 'col-sm-6',
                      ]"
                    >
                      <v-text-field
                        v-model="collection_address.name"
                        :label="$t('receiver_name')"
                        clearable
                        outlined
                        dense
                        :error-messages="nameErrors"
                        @input="$v.collection_address.name.$touch()"
                        @blur="$v.collection_address.name.$touch()"
                      ></v-text-field>
                    </div>

                    <div
                      class="col-12"
                      :class="[
                        order_details.collection_type_id === 1
                          ? 'col-sm-3'
                          : 'col-sm-6',
                      ]"
                    >
                      <vue-tel-input
                        ref="phone"
                        v-model="collection_address.phone"
                        :autoFormat="false"
                        :defaultCountry="966"
                        :dropdownOptions="{
                          showDialCodeInSelection: true,
                          showFlags: true,
                          showSearchBox: true,
                          width: '290px',
                        }"
                        :inputOptions="{
                          required: true,
                          placeholder: `${$t('receiver_phone')}`,
                          styleClasses: 'sender-phone',
                        }"
                      ></vue-tel-input>
                      <p v-if="phoneError" class="phone-error">
                        {{ phoneError }}
                      </p>
                    </div>

                    <div
                      class="col-12"
                      :class="[
                        order_details.collection_type_id === 1
                          ? 'col-sm-3'
                          : 'col-sm-6',
                      ]"
                    >
                      <v-text-field
                        v-model="collection_address.email"
                        :label="$t('sender_email')"
                        clearable
                        outlined
                        dense
                        :error-messages="emailErrors"
                        @input="$v.collection_address.email.$touch()"
                        @blur="$v.collection_address.email.$touch()"
                      ></v-text-field>
                    </div>

                    <div
                      class="col-12 col-sm-3"
                      v-if="order_details.collection_type_id === 1"
                    >
                      <v-text-field
                        v-model="collection_address.short_address"
                        :label="$t('short_address')"
                        clearable
                        outlined
                        dense
                        @keyup.enter="searchShortAddress"
                        append-icon="mdi-barcode-scan"
                        @click:append="searchShortAddress"
                        hint="Press enter to search after entering short address"
                      ></v-text-field>
                    </div>
                    <!-- row 1 end -->

                    <!-- row 2 start -->
                    <div
                      v-if="order_details.collection_type_id === 1"
                      class="col-12 col-sm-6 col-md-3 pt-0"
                    >
                      <h6>{{ $t("address_type") }}</h6>
                      <v-radio-group
                        row
                        v-model="collection_address.collection_address_type"
                      >
                        <v-radio :label="$t('national')" :value="2"></v-radio>
                        <v-radio
                          :label="$t('descriptive')"
                          :value="6"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                      <v-autocomplete
                        @change="onCollectionCountryChange"
                        v-model="collection_address.country"
                        :label="$t('country')"
                        item-text="text"
                        item-value="index"
                        :items="serverData.countries"
                        clearable
                        outlined
                        dense
                        :error-messages="collectionCountryErrors"
                        @input="$v.collection_address.country.$touch()"
                        @blur="$v.collection_address.country.$touch()"
                      ></v-autocomplete>
                    </div>

                    <div class="col-12 col-sm-6 col-md-3">
                      <v-combobox
                        v-model="collection_address.state"
                        :search-input.sync="collection_state_text"
                        :items="collectionAddressServerData.states"
                        :disabled="isCollectionStatesLoaded"
                        :rules="[validationValueCheck('collection_state')]"
                        item-text="title"
                        item-value="id"
                        :label="$t('state')"
                        persistent-hint
                        outlined
                        dense
                        :error-messages="collectionStateErrors"
                        @input="$v.collection_address.state.$touch()"
                        @blur="$v.collection_address.state.$touch()"
                        @change="onCollectionStateChange"
                        @keyup="updateValue('collection_state')"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("no_results_matching") }}"<strong>{{
                                  collection_state_text
                                }}</strong
                                >".
                                <button
                                  class="btn btn-info pl-2"
                                  @click="
                                    createNewItem(
                                      'state',
                                      collection_address.country,
                                      onCollectionCountryChange,
                                      collection_state_text
                                    )
                                  "
                                >
                                  {{ $t("create_new") }}
                                </button>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </div>
                    <!-- row 2 end -->

                    <div class="col-12 col-sm-6 col-md-3">
                      <v-combobox
                        v-model="collection_address.city"
                        :search-input.sync="collection_city_text"
                        :items="collectionAddressServerData.cities"
                        :disabled="isCollectionCitiesLoaded"
                        :rules="[validationValueCheck('collection_city')]"
                        item-text="title"
                        item-value="id"
                        :label="$t('city')"
                        persistent-hint
                        outlined
                        dense
                        :error-messages="collectionCityErrors"
                        @input="$v.collection_address.city.$touch()"
                        @blur="$v.collection_address.city.$touch()"
                        @change="onCollectionCityChange"
                        @keyup="updateValue('collection_city')"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("no_results_matching") }}
                                "<strong>{{ collection_city_text }}</strong
                                >".
                                <button
                                  class="btn btn-info pl-2"
                                  @click="
                                    createNewItem(
                                      'city',
                                      collection_address.state.id,
                                      onCollectionStateChange,
                                      collection_city_text
                                    )
                                  "
                                >
                                  {{ $t("create_new") }}
                                </button>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </div>

                    <template v-if="order_details.collection_type_id === 1">
                      <div class="col-12 col-sm-6 col-md-3">
                        <v-combobox
                          v-model="collection_address.area"
                          :search-input.sync="collection_area_text"
                          :items="collectionAddressServerData.areas"
                          :rules="[validationValueCheck('collection_area')]"
                          item-text="title"
                          item-value="title"
                          :label="$t('area')"
                          persistent-hint
                          outlined
                          :disabled="isCollectionAreasLoaded"
                          dense
                          @keyup="updateValue('collection_area')"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ $t("no_results_matching") }}
                                  "<strong>{{ collection_area_text }}</strong
                                  >".
                                  <button
                                    class="btn btn-info pl-2"
                                    @click="
                                      createNewItem(
                                        'area',
                                        collection_address.city.id,
                                        onCollectionCityChange,
                                        collection_area_text
                                      )
                                    "
                                  >
                                    {{ $t("create_new") }}
                                  </button>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-combobox>
                      </div>
                    </template>

                    <template v-else>
                      <div class="col-12 col-sm-6 col-md-3">
                        <v-autocomplete
                          :disabled="isCollectionOfficesLoaded"
                          v-model="collection_address.drop_off_office_id"
                          :items="collectionAddressServerData.offices"
                          item-text="text"
                          item-value="index"
                          :label="$t('office')"
                          outlined
                          dense
                          :error-messages="collectionOfficeErrors"
                          @input="
                            $v.collection_address.drop_off_office_id.$touch()
                          "
                          @blur="
                            $v.collection_address.drop_off_office_id.$touch()
                          "
                        />
                      </div>
                    </template>
                    <!-- row 3 end -->

                    <template v-if="order_details.collection_type_id === 1">
                      <div v-if="!isAddressNational" class="col-12 col-sm-9">
                        <v-text-field
                          v-model="collection_address.address"
                          :label="$t('address')"
                          clearable
                          outlined
                          dense
                          :error-messages="addressErrors"
                          @input="$v.collection_address.address.$touch()"
                          @blur="$v.collection_address.address.$touch()"
                        ></v-text-field>
                      </div>
                      <div v-if="!isAddressNational" class="col-6">
                        <v-text-field
                          v-model="collection_address.address_2"
                          :label="$t('address_2')"
                          clearable
                          outlined
                          dense
                          :error-messages="address2Errors"
                          @input="$v.collection_address.address_2.$touch()"
                          @blur="$v.collection_address.address.$touch()"
                        ></v-text-field>
                      </div>
                      <div
                        v-if="isAddressNational"
                        class="col-12 col-sm-6 col-md-4"
                      >
                        <v-text-field
                          v-model="collection_address.building_no"
                          :label="$t('building_no')"
                          clearable
                          outlined
                          dense
                          :error-messages="buildingNoErrors"
                          @input="$v.collection_address.building_no.$touch()"
                          @blur="$v.collection_address.building_no.$touch()"
                        ></v-text-field>
                      </div>
                      <div v-if="isAddressNational" class="col-12 col-sm-5">
                        <v-text-field
                          v-model="collection_address.unit_no"
                          :label="$t('unit_no')"
                          clearable
                          outlined
                          dense
                          :error-messages="unitNoErrors"
                          @input="$v.collection_address.unit_no.$touch()"
                          @blur="$v.collection_address.unit_no.$touch()"
                        ></v-text-field>
                      </div>
                      <div v-if="isAddressNational" class="col-12 col-sm-3">
                        <v-text-field
                          v-model="collection_address.additional_no"
                          :label="$t('additional_no')"
                          clearable
                          outlined
                          dense
                          :error-messages="additionalNoErrors"
                          @input="$v.collection_address.additional_no.$touch()"
                          @blur="$v.collection_address.additional_no.$touch()"
                        ></v-text-field>
                      </div>
                      <div v-if="isAddressNational" class="col-12 col-sm-4">
                        <v-text-field
                          v-model="collection_address.postal_code"
                          :label="$t('zip_code')"
                          clearable
                          outlined
                          dense
                          :error-messages="postalCodeErrors"
                          @input="$v.collection_address.postal_code.$touch()"
                          @blur="$v.collection_address.postal_code.$touch()"
                        ></v-text-field>
                      </div>
                      <div
                        :class="[
                          'col-12',
                          !isAddressNational ? 'col-md-6' : 'col-md-5',
                        ]"
                      >
                        <v-text-field
                          v-model="collection_address.street"
                          :label="$t('street')"
                          clearable
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </template>
                  </div>
                </v-card>
              </div>

              <div class="mt-1">
                <v-card class="px-4">
                  <v-card-title class="px-0 h2 py-1">{{
                    $t("receiver_details")
                  }}</v-card-title>
                  <div class="row">
                    <div class="col-12">
                      <v-autocomplete
                        v-model="collection_address.warehouse_id"
                        :label="$t('warehouse')"
                        item-text="text"
                        item-value="index"
                        :items="serverData.warehouses"
                        clearable
                        outlined
                        dense
                        :error-messages="warehouse_idErrors"
                        @input="$v.collection_address.warehouse_id.$touch()"
                        @blur="$v.collection_address.warehouse_id.$touch()"
                      ></v-autocomplete>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2" class="pt-2">
              <v-card color="basil" flat>
                <v-col cols="12">
                  <sku-datatable
                    :setSku="setSkus"
                    :skus="formData.skus"
                    :skuItem="[]"
                    :clientId="userId"
                    :is_unit_price_required="get_is_unit_price_required"
                    :categories="serverData.categories"
                  ></sku-datatable>
                </v-col>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <CreateLocation
          :location-id="getLocationId"
          :location-type="getLocationType"
          :location-function="getLocationFunc"
          :locationSetValue="setTextValue"
          :initValue="getLocationInitValue"
          ref="location"
        ></CreateLocation>

        <div class="d-flex align-center modal-action flex-wrap pt-4">
          <div class="justify-content-between align-center d-flex col-12">
            <v-btn
              v-if="backState"
              class="order-1 order-sm-1"
              @click="nextStep('prev')"
              :color="$store.getters.currentUser.theme['text_color']"
              dark
              ><v-icon>{{
                $vuetify.rtl ? "mdi-arrow-right" : "mdi-arrow-left"
              }}</v-icon></v-btn
            >
            <div v-else class="order-1 order-sm-1"></div>
            <div
              class="order-2 order-sm-2 d-flex flex-wrap justify-content-center align-center"
              :class="{ invisible: nextState }"
            >
              <!--              <button-->
              <!--                type="submit"-->
              <!--                class="btn btn-danger px-5 py-3 mx-2 order-2 mt-1 mt-sm-0"-->
              <!--                @click="toggleModal"-->
              <!--              >-->
              <!--                {{ $t("close") }}-->
              <!--              </button>-->
              <button
                type="submit"
                class="btn btn--export-filter px-5 py-3 mx-2 order-1 mt-1 mt-sm-0"
                @click="submitCreateForm"
              >
                {{ $t("create_return") }}
              </button>
            </div>
            <v-btn
              v-if="nextState"
              class="order-3 order-sm-3"
              @click="nextStep('next')"
              :color="$store.getters.currentUser.theme['text_color']"
              dark
              ><v-icon>{{
                $vuetify.rtl ? "mdi-arrow-left" : "mdi-arrow-right"
              }}</v-icon></v-btn
            >
            <div v-else class="order-3 order-sm-3"></div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";
import SkuDatatable from "@/own/components/fulfillment/returned/CreateReturned/SkuDatatable.vue";
import validationMixin from "@/own/mixins/validationMixin.js";
import {
  maxLength,
  minLength,
  numeric,
  required,
  requiredIf,
  email,
} from "vuelidate/lib/validators";
import CreateLocation from "./CreateLocation.vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/css/sprite.css"; // Flags styles
import "vue-tel-input/dist/css/component.css"; // Component styles

const mustHaveSku = (data) => data.length > 0;

export default {
  name: "CreateItemNew",
  mixins: [validationMixin],
  components: {
    SkuDatatable,
    CreateLocation,
    // VueTelInputVuetify,
    VueTelInput,
  },

  props: {
    refresher: {
      required: true,
      type: Function,
    },
    pageLoader: {
      required: true,
      type: Function,
    },
    itemForAction: {
      required: false,
      type: Number,
    },
  },

  validations() {
    return {
      order_details: {
        customer_id: {
          required: requiredIf(function() {
            return !this.isClient;
          }),
        },
        collection_type_id: { required },
      },
      collection_address: {
        name: { required },
        phone: {
          required,
          maxLength: maxLength(9),
          minLength: minLength(6),
          numeric,
        },
        email: { email },
        drop_off_office_id: {
          required: requiredIf(function() {
            return this.order_details.collection_type_id === 2;
          }),
        },
        collection_address_type: {
          required: requiredIf(function() {
            return !this.order_details.collection_type_id === 1;
          }),
        },
        city: { required },
        country: { required },
        state: { required },
        building_no: {
          maxLength: maxLength(4),
          numeric,
          required: requiredIf(() => this.isAddressNational),
        },
        unit_no: {
          maxLength: maxLength(4),
          numeric,
          required: requiredIf(() => this.isAddressNational),
        },
        additional_no: {
          maxLength: maxLength(4),
          numeric,
          required: requiredIf(() => this.isAddressNational),
        },
        postal_code: {
          maxLength: maxLength(5),
          numeric,
          required: requiredIf(() => this.isAddressNational),
        },
        warehouse_id: { required },
        address: {
          required: requiredIf(function() {
            return (
              this.collection_address.collection_address_type === 6 &&
              this.order_details.collection_type_id === 1
            );
          }),
        },
        address_2: {
          required: requiredIf(function() {
            return (
              this.collection_address.collection_address_type === 6 &&
              this.order_details.collection_type_id === 1
            );
          }),
        },
      },
      formData: {
        skus: { required, mustHaveSku },
      },
    };
  },
  data() {
    return {
      tabValidationState: [true, true, true, true, true, true],

      collection_state_text: null,
      collection_city_text: null,
      collection_area_text: null,

      location_initValue: null,

      collection_address: {
        name: null,
        country_code: null,
        phone: null,
        email: null,
        collection_address_type: 6,
        address: null,
        address_2: null,
        short_address: null,
        area: null,
        drop_off_office_id: null,
        city: null,
        country: null,
        state: null,
        street: null,
        building_no: null,
        unit_no: null,
        additional_no: null,
        postal_code: null,
        warehouse_id: null,
      },

      order_details: {
        collection_type_id: 1,
        original_awb: null,
        note: null,
        customer_id: null,
        // expected_collection_date: null,
        original_client_reference: null,
      },
      formData: {
        skus: [],
      },

      dialog: false,
      serverData: {},
      collectionAddressServerData: {},
      tab: 0,
      categories: [
        {
          name: this.$t("order_details"),
          valid: true,
          rules: (el) => {
            try {
              // console.log(el[`$v`][`order_details`]);
              return el[`$v`][`order_details`].$invalid;
            } catch {
              return true;
            }
          },
        },

        {
          name: this.$t("addresses"),
          valid: true,
          rules: (el) => {
            try {
              return el[`$v`][`collection_address`].$invalid;
            } catch {
              return true;
            }
          },
        },
        {
          name: this.$t("boxes"),
          valid: true,
          rules: (el) => {
            try {
              return !el.formData.skus.length > 0;
            } catch (error) {
              return true;
            }
          },
        },
      ],

      location_type: null,
      locaiton_id: null,
      location_function: null,
    };
  },
  methods: {
    nextStep(type) {
      // console.log(this.collection_address);
      this.updateValidationStateOfSteps();
      if (type == "next") {
        if (this.tab < 3) {
          this.tab += 1;
        }
      } else {
        if (this.tab > 0) {
          this.tab -= 1;
        }
      }
    },
    updateValue(type) {
      let timer;
      const waitTime = 1250;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, waitTime);
    },
    onBlurValueCheck(type) {
      switch (type) {
        case "collection_state": {
          const item = this.collectionAddressServerData.states.find(
            (state) => state.title == this.collection_state_text
          );

          if (!item) {
            // } else {
            this.onCollectionStateChange(item);
          }

          break;
        }

        case "collection_city": {
          const item = this.collectionAddressServerData.cities.find(
            (city) => city.title == this.collection_city_text
          );

          if (!item) {
            // else {
            this.onCollectionCityChange(item);
          }
          break;
        }

        case "collection_area": {
          const item = this.collectionAddressServerData.areas.find(
            (area) => area.title == this.collection_area_text
          );

          if (!item) {
            // } else {
            this.collection_address.area = item.title;
          }
          break;
        }
      }
    },
    validationValueCheck(type) {
      let validation = true;
      switch (type) {
        case "collection_state":
          try {
            if (
              !this.collectionAddressServerData.states.find(
                (state) => state.title == this.collection_state_text
              )
            ) {
              validation = this.$t("please_select_valid_state");
            }
          } catch {
            break;
          }

          break;

        case "collection_city":
          try {
            if (
              !this.collectionAddressServerData.cities.find(
                (city) => city.title == this.collection_city_text
              )
            ) {
              validation = this.$t("please_select_valid_city");
              if (this.order_details.collection_type_id === 2) {
                this.collectionAddressServerData.offices = null;
              } else this.collectionAddressServerData.areas = null;
            }
          } catch {
            break;
          }

          break;

        case "collection_area":
          try {
            if (!this.collection_area_text) {
              validation = true;
              break;
            } else if (
              !this.collectionAddressServerData.areas.find(
                (area) => area.title == this.collection_area_text
              )
            ) {
              validation = this.$t("please_select_valid_area");
            }
          } catch {
            break;
          }

          break;
      }
      return validation;
    },
    async setTextValue(type, value = "") {
      switch (type) {
        case "collection_state":
          this.collection_state_text = value;

          if (value !== "") {
            this.collection_address.state = this.collectionAddressServerData.states.find(
              (state) => state.title == this.collection_state_text
            );
            await this.onCollectionStateChange(this.collection_address.state);
          }

          break;

        case "collection_city":
          this.city_text = value;

          if (value !== "") {
            this.collection_address.city = this.collectionAddressServerData.cities.find(
              (city) => city.title == this.city_text
            );
            await this.onCollectionCityChange(this.collection_address.city);
          }
          break;

        case "collection_area":
          this.collection_area_text = value;
          break;
      }
    },
    createNewItem(type, id, func, value = "") {
      // console.log(type, id, func);
      this.location_type = type;
      this.locaiton_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    updateValidationStateOfSteps() {
      // console.log(`working`);
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
        // console.log(cat);
      });
    },
    resetValidationStateOfSteps() {
      this.categories.forEach((cat) => {
        cat.valid = true;
      });
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    dateFormatter(date) {
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    async loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.itemForAction };
      await ApiService.post(`/shipping/returns_management/create`, data)
        .then((response) => {
          this.serverData = response.data;
          setTimeout(() => {
            this.collection_address.country = 191;
          }, 300);
          this.onCollectionCountryChange(191);
          this.resetEditForm().finally(() => {
            this.pageLoader(false);
            this.updateValidationStateOfSteps();
          });
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },

    submitCreateForm() {
      this.updateValidationStateOfSteps();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.collection_address.phone[0] === "+") {
        this.collection_address.phone = this.collection_address.phone.replace(
          `+${this.collection_address.country_code} `,
          ""
        );
      }
      // console.log("this.collection_address", this.collection_address);
      const data = {
        original_client_reference: this.order_details.original_client_reference,
        customer_id: this.order_details.customer_id,
        collection_type_id: this.order_details.collection_type_id,
        collection_address_type: this.collection_address
          .collection_address_type,
        original_awb: this.order_details.original_awb,
        comment: this.order_details.note,

        collection_address: {
          name: this.collection_address.name,
          phone: this.collection_address.phone.replace(/ /g, ""),
          email: this.collection_address.email,
          country_code: this.countryCode,
          country: this.collection_address.country,
          state: this.collection_address.state.id,
          city: this.collection_address.city.id,
          address_2: this.collection_address.address_2,
          short_address: this.collection_address.short_address,
          address: this.collection_address.address,
          building_no: this.collection_address.building_no,
          unit_no: this.collection_address.unit_no,
          additional_no: this.collection_address.additional_no,
          postal_code: this.collection_address.postal_code,
          street: this.collection_address.street,
        },

        warehouse_id: this.collection_address.warehouse_id,
        skus: this.formData.skus,
      };
      try {
        if (this.order_details.collection_type_id === 2) {
          data.drop_off_office_id = this.collection_address.drop_off_office_id;
        } else {
          data.collection_address.area = this.collection_address.area.id;
          // data.expected_collection_date = this.order_details
          //   .expected_collection_date
          //   ? this.dateFormatter(this.order_details.expected_collection_date)
          //   : null;
        }
      } catch {
        data.collection_address.area = null;
      }

      this.pageLoader(true);

      ApiService.post(`/shipping/returns_management/store`, data)
        .then(() => {
          SwalService.successMessage({
            title: this.$t("created"),
            html: this.$t("order_has_been_created"),
          });
          this.refresher();
          this.toggleModal();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    async resetEditForm() {
      this.$v.$reset();

      this.collection_address = {
        name: null,
        phone: null,
        email: null,
        collection_address_type: 6,
        company: null,
        address: null,
        address_2: null,
        short_address: null,
        area: null,
        drop_off_office_id: null,
        city: null,
        country: null,
        country_code: "966",
        state: null,
        street: null,
        building_no: null,
        unit_no: null,
        additional_no: null,
        postal_code: null,
        warehouse_id: null,
      };
      this.order_details = {
        note: null,
        customer_id: null,
        collection_type_id: 1,
        warehouse: null,
        original_client_reference: null,
        // expected_collection_date: null,
      };
      this.formData = {
        skus: [],
      };
      this.tab = 0;
      await this.resetValidationStateOfSteps();
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
    searchShortAddress() {
      if (this.collection_address.short_address) {
        this.pageLoader(true);
        ApiService.post("/address/spl/short", {
          short: this.collection_address.short_address,
        })
          .then(async ({ data }) => {
            if (data.success) {
              this.collection_address.collection_address_type = 2;
            }
            this.collection_address.country_code = data.address.country_code;
            this.collection_address.country = data.address.country_id;

            this.collectionAddressServerData.states = data.states;
            this.collectionAddressServerData.cities = data.cities;
            this.collectionAddressServerData.areas = data.areas;
            const state = data.states.find(
              (state) => state.id === data.address.state_id
            );

            this.collection_address.state = { ...state };

            await this.onCollectionStateChange(state);

            if (data.address.city_title) {
              const city = data.cities.find(
                (city) => city.id === data.address.city_id
              );

              this.collection_address.city = { ...city };

              await this.onCollectionCityChange(city);
              this.collection_city_text = String(data.address.city_title);
            } else {
              this.collection_address.city = null;
              this.collection_city_text = null;
            }

            if (data.address.area_title) {
              this.collection_address.area = String(data.address.area_title);
              this.collection_area_text = String(data.address.area_title);
            } else {
              this.collection_address.area = null;
              this.collection_area_text = null;
            }

            this.collection_address.address = data.address.address;
            this.collection_address.address_2 = data.address.address_2;
            this.collection_address.building_no = data.address.building_no;
            this.collection_address.additional_no = data.address.additional_no;
            this.collection_address.street = data.address.street;
            this.collection_address.unit_no = data.address.unit_no;
            this.collection_address.postal_code = data.address.postal_code;
          })
          .finally(() => {
            this.pageLoader(false);
          });
      }
    },
    async onCollectionCountryChange(val, is_id = false) {
      if (val) {
        this.pageLoader(true);
        let data = is_id ? { country: val } : { country: val };
        await ApiService.post("/address/states/search", data)
          .then((response) => {
            this.collectionAddressServerData.states = response.data.states;

            this.collectionAddressServerData = {
              ...this.collectionAddressServerData,
            };
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },

    async onCollectionStateChange(val, is_id = false) {
      if (val) {
        let data = { state: null };
        if (is_id) {
          data.state = val;
        } else {
          let id = null;
          try {
            // console.log(this.collectionAddressServerData.states);
            id = this.collectionAddressServerData.states.find(
              (state) => state.title == val.title
            ).id;
          } catch {
            return;
          }
          if (!id) {
            return;
          }
          data.state = id;
        }
        this.pageLoader(true);

        await ApiService.post("/address/cities/search", data)
          .then((response) => {
            // this.serverCities = response.data.cities;
            this.collectionAddressServerData.cities = response.data.cities;

            this.collectionAddressServerData = {
              ...this.collectionAddressServerData,
            };

            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },

    // async onCollectionCityChange(val, safe = false, is_id = false) {
    async onCollectionCityChange(
      val,
      is_id = false,
      collection_type_id = this.order_details.collection_type_id
    ) {
      if (val) {
        let data = { city: null };
        if (is_id) {
          data.city = val;
        } else {
          let id = null;
          try {
            id = this.collectionAddressServerData.cities.find(
              (city) => city.title == val.title
            ).id;
          } catch {
            return;
          }
          if (!id) {
            return;
          }

          data.city = id;
        }
        const url =
          collection_type_id === 2
            ? "/address/spl/offices/search"
            : "/address/areas/search";

        this.pageLoader(true);
        await ApiService.post(url, data)
          .then((response) => {
            if (collection_type_id === 2) {
              this.collectionAddressServerData.offices = response.data.offices;
            } else this.collectionAddressServerData.areas = response.data.areas;

            this.collectionAddressServerData = {
              ...this.collectionAddressServerData,
            };
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
  },
  computed: {
    backState() {
      return this.tab !== 0;
    },
    nextState() {
      return this.tab !== 2;
    },
    getLocationType() {
      return this.location_type;
    },
    getLocationId() {
      return this.locaiton_id;
    },
    getLocationFunc() {
      return this.location_function;
    },
    getLocationInitValue() {
      return this.location_initValue;
    },
    get_is_unit_price_required() {
      let data = null;
      try {
        data = this.serverData.countries.find(
          (country) => country.index === this.shipping_address.country
        ).unit_price_required;
      } catch {
        data = null;
      }
      return data;
    },
    isClient() {
      return this.$store.getters.isClient;
      // return true;
    },
    isStepperReady() {
      try {
        return !!this.$v;
      } catch (error) {
        return false;
      }
    },
    userId: function() {
      return this.$store.getters.currentUser.data.is_client
        ? this.$store.getters.currentUser.data.id
        : this.order_details.customer_id;
    },

    isCollectionStatesLoaded: function() {
      return !this.collectionAddressServerData.states;
    },

    isCollectionCitiesLoaded: function() {
      return !this.collectionAddressServerData.cities;
    },

    isCollectionAreasLoaded: function() {
      return !this.collectionAddressServerData.areas;
    },

    isCollectionOfficesLoaded: function() {
      return !this.collectionAddressServerData.offices;
    },

    isDataLoadedFromServer: function() {
      return !!this.serverData;
    },

    customerIdErrors: function() {
      return this.handleFormValidation("customer_id", this, "order_details");
    },
    collectionTypeIdErrors: function() {
      return this.handleFormValidation(
        "collection_type_id",
        this,
        "order_details"
      );
    },
    collectionTypes: function() {
      return [
        { index: 1, text: this.$t("pickup") },
        { index: 2, text: this.$t("dropoff") },
      ];
    },
    collectionStateErrors: function() {
      return this.handleFormValidation("state", this, "collection_address");
    },
    collectionCityErrors: function() {
      return this.handleFormValidation("city", this, "collection_address");
    },
    collectionCountryErrors: function() {
      return this.handleFormValidation("country", this, "collection_address");
    },
    nameErrors: function() {
      return this.handleFormValidation("name", this, "collection_address");
    },
    warehouse_idErrors: function() {
      return this.handleFormValidation(
        "warehouse_id",
        this,
        "collection_address"
      );
    },
    collectionOfficeErrors: function() {
      return this.handleFormValidation(
        "drop_off_office_id",
        this,
        "collection_address"
      );
    },
    countryCode: function() {
      return this.$refs.phone.activeCountry.dialCode;
    },
    phoneError: function() {
      const pattern = /^\d{6,9}$/;
      if (!this.collection_address.phone) {
        return this.$t("this_field_is_required");
      } else if (!pattern.test(this.collection_address.phone)) {
        return this.$t("invalid_phone_number");
      }
      return null;
      // return this.handleFormValidation("phone", this, "collection_address", {
      //   minLength: 6,
      //   maxLength: 9,
      // });
    },
    emailErrors: function() {
      return this.handleFormValidation("email", this, "collection_address");
    },
    buildingNoErrors: function() {
      return this.handleFormValidation(
        "building_no",
        this,
        "collection_address",
        { maxLength: 4 }
      );
    },
    addressErrors: function() {
      return this.handleFormValidation("address", this, "collection_address");
    },
    address2Errors: function() {
      return this.handleFormValidation("address_2", this, "collection_address");
    },
    unitNoErrors: function() {
      return this.handleFormValidation("unit_no", this, "collection_address", {
        maxLength: 4,
      });
    },
    additionalNoErrors: function() {
      return this.handleFormValidation(
        "additional_no",
        this,
        "collection_address",
        { maxLength: 4 }
      );
    },
    postalCodeErrors: function() {
      return this.handleFormValidation(
        "postal_code",
        this,
        "collection_address",
        { maxLength: 5 }
      );
    },
    isAddressNational: function() {
      return (
        this.collection_address.collection_address_type === 2 &&
        this.order_details.collection_type_id === 1
      );
    },
  },
};
</script>

<style scoped>
.modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
}
.modal-root {
  background-color: white !important;
  height: 100%;
}
.modal-action {
  justify-self: flex-end;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}
.form-body {
  max-height: 64vh !important;
  height: 64vh !important;
  overflow-y: scroll;
}
.step-head-main {
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}
</style>
<style lang="scss">
.return-original-order-radio {
  .v-label {
    font-size: 12px;
  }
}
.vue-tel-input {
  height: 41px;
  border: 2px solid #dcdfe8 !important;
  border-radius: 4px;
  box-shadow: none !important;
}
.phone-error {
  color: #fd397a;
  font-size: 12px;
}
.sender-phone::placeholder {
  color: #fd397a !important;
}
</style>
